import React from 'react'
export const chevron_up = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.12117 7.77815C1.73064 8.16868 1.09748 8.16868 0.706956 7.77815C0.316431 7.38763 0.316431 6.75446 0.706956 6.36394L6.36392 0.707086C6.75444 0.316571 7.3876 0.316572 7.77812 0.707088L13.435 6.36384C13.8255 6.75437 13.8255 7.38753 13.435 7.77806C13.0445 8.16858 12.4113 8.16858 12.0208 7.77806L7.07102 2.82831L2.12117 7.77815Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 14 9',
}
