import React from 'react'
export const twitter = {
  path: (
    <path
      d="M18 2.22559C17.332 2.52441 16.6289 2.72656 15.873 2.81445C16.6465 2.35742 17.2266 1.62793 17.499 0.766602C16.7959 1.18848 15.9961 1.49609 15.1699 1.66309C14.4756 0.959961 13.5176 0.50293 12.4541 0.50293C10.415 0.50293 8.77148 2.14648 8.77148 4.18555C8.77148 4.47559 8.78906 4.77441 8.85059 5.03809C5.79199 4.87109 3.08496 3.48242 1.24805 1.25C0.94043 1.79492 0.74707 2.35742 0.74707 3.04297C0.74707 4.30859 1.39746 5.43359 2.39941 6.10156C1.79297 6.08398 1.23047 5.91699 0.729492 5.64453V5.68848C0.729492 7.48145 2.00391 8.97559 3.69141 9.30957C3.375 9.39746 3.04102 9.44141 2.70703 9.44141C2.47852 9.44141 2.25 9.41504 2.02148 9.37109C2.47852 10.8301 3.8584 11.9199 5.45801 11.9375C4.20996 12.9395 2.60156 13.6865 0.878906 13.6865C0.580078 13.6865 0.290039 13.6689 0 13.625C1.62598 14.6709 3.58594 15.1279 5.66895 15.1279C12.4541 15.1279 16.1631 9.50293 16.1631 4.625V4.1416C16.875 3.62305 17.499 2.98145 18 2.22559Z"
      fill="#25273D"
    />
  ),
  viewBox: '0 0 18 16',
}
