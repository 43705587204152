import React from 'react'
export const bridge = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.06454 10.1944C5.4498 7.81599 7.51281 6 9.99997 6C12.4871 6 14.5502 7.81599 14.9354 10.1944C15.2663 10.0688 15.6251 10 16 10C16.3317 10 16.6509 10.0539 16.9493 10.1533C16.5313 6.68659 13.5794 4 9.99997 4C6.42056 4 3.46863 6.68659 3.05066 10.1533C3.34901 10.0539 3.6682 10 3.99997 10C4.37486 10 4.73369 10.0688 5.06454 10.1944ZM18 13C18 14.1046 17.1046 15 16 15C14.8954 15 14 14.1046 14 13C14 11.8954 14.8954 11 16 11C17.1046 11 18 11.8954 18 13ZM6 13C6 14.1046 5.10457 15 4 15C2.89543 15 2 14.1046 2 13C2 11.8954 2.89543 11 4 11C5.10457 11 6 11.8954 6 13ZM5 13C5 13.5523 4.55228 14 4 14C3.44772 14 3 13.5523 3 13C3 12.4477 3.44772 12 4 12C4.55228 12 5 12.4477 5 13Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
}
