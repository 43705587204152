import React from 'react'
export const star = {
  path: (
    <path
      d="M7.28261 1.71283C7.57606 1.11824 8.42394 1.11824 8.71739 1.71284L10.165 4.64603C10.2815 4.88214 10.5068 5.0458 10.7674 5.08366L14.0043 5.55402C14.6605 5.64937 14.9225 6.45574 14.4477 6.91857L12.1054 9.20174C11.9169 9.38553 11.8308 9.65033 11.8753 9.90985L12.4283 13.1337C12.5404 13.7873 11.8544 14.2856 11.2675 13.9771L8.37227 12.455C8.13921 12.3324 7.86079 12.3324 7.62773 12.455L4.73249 13.9771C4.14559 14.2856 3.45965 13.7873 3.57174 13.1337L4.12468 9.90985C4.16919 9.65033 4.08315 9.38553 3.8946 9.20174L1.55231 6.91857C1.07749 6.45574 1.3395 5.64937 1.99568 5.55402L5.23265 5.08366C5.49321 5.0458 5.71847 4.88214 5.835 4.64603L7.28261 1.71283Z"
      strokeLinejoin="round"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 16 15',
}
