import React from 'react'
export const discord = {
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.09668 10.9676C7.09668 10.2585 7.60691 9.67725 8.25321 9.67725C8.8995 9.67725 9.42107 10.2585 9.40974 10.9676C9.40974 11.6767 8.8995 12.2579 8.25321 12.2579C7.61825 12.2579 7.09668 11.6767 7.09668 10.9676ZM11.2352 10.9677C11.2352 10.2586 11.7454 9.67742 12.3917 9.67742C13.038 9.67742 13.5483 10.2586 13.5483 10.9677C13.5483 11.6768 13.038 12.2581 12.3917 12.2581C11.7568 12.2581 11.2352 11.6768 11.2352 10.9677Z"
        fill="#25273D"
        key="FV4HZOD8Q"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.34286 0H17.6571C18.9486 0 20 1.03871 20 2.32581V22.5806L17.5429 20.4355L16.16 19.171L14.6971 17.8274L15.3029 19.9161H2.34286C1.05143 19.9161 0 18.8774 0 17.5903V2.32581C0 1.03871 1.05143 0 2.34286 0ZM12.4915 13.8307C12.8343 14.2597 13.2458 14.7452 13.2458 14.7452C15.7715 14.6662 16.7429 13.0291 16.7429 13.0291C16.7429 9.39358 15.0972 6.44681 15.0972 6.44681C13.4515 5.22745 11.8858 5.26132 11.8858 5.26132L11.7258 5.44197C13.6686 6.02907 14.5715 6.87584 14.5715 6.87584C13.3829 6.23229 12.2172 5.91616 11.1315 5.79197C10.3086 5.70165 9.52006 5.72423 8.82291 5.81455C8.76305 5.81455 8.7119 5.82315 8.65426 5.83285C8.64587 5.83426 8.63734 5.8357 8.62863 5.83713C8.22863 5.871 7.2572 6.01778 6.03434 6.54842C5.61149 6.74036 5.36006 6.87584 5.36006 6.87584C5.36006 6.87584 6.30863 5.9839 8.36577 5.39681L8.25149 5.26132C8.25149 5.26132 6.68577 5.22745 5.04006 6.44681C5.04006 6.44681 3.39434 9.39358 3.39434 13.0291C3.39434 13.0291 4.35434 14.6662 6.88006 14.7452C6.88006 14.7452 7.30291 14.2371 7.64577 13.8081C6.19434 13.3791 5.64577 12.4758 5.64577 12.4758C5.64577 12.4758 5.76006 12.5549 5.96577 12.6678C5.9772 12.6791 5.98863 12.6904 6.01149 12.7016C6.02863 12.7129 6.04577 12.7214 6.06291 12.7299C6.08006 12.7383 6.0972 12.7468 6.11434 12.7581C6.40006 12.9162 6.68577 13.0404 6.94863 13.142C7.4172 13.3226 7.9772 13.5033 8.62863 13.6275C9.48577 13.7855 10.4915 13.842 11.5886 13.6387C12.1258 13.5484 12.6743 13.3904 13.2458 13.1533C13.6458 13.0065 14.0915 12.792 14.5601 12.4871C14.5601 12.4871 13.9886 13.4129 12.4915 13.8307Z"
        fill="#25273D"
        key="hR7_KSN7WP"
      />
    </>
  ),
  viewBox: '0 0 20 23',
}
