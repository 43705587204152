import React from 'react'
export const arrow_right_light = {
  path: (
    <path
      d="M1 4.5H11M11 4.5L8 8M11 4.5L8 1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  viewBox: '0 0 12 9',
}
