import React from 'react'
export const rays = {
  path: (
    <>
      <g clipPath="url(#clip0_6195_10517)">
        <path
          d="M0.570738 9.26067C0.321451 9.0944 0.433747 8.67531 0.732769 8.65597C2.63283 8.53303 6.72069 8.12232 9.00407 6.80401C11.2874 5.4857 13.6871 2.15086 14.7436 0.566832C14.9098 0.317545 15.3289 0.429841 15.3483 0.728863C15.4712 2.62892 15.8819 6.71678 17.2002 9.00016C18.5185 11.2835 21.8534 13.6832 23.4374 14.7397C23.6867 14.9059 23.5744 15.325 23.2754 15.3444C21.3753 15.4673 17.2874 15.878 15.0041 17.1963C12.7207 18.5146 10.3211 21.8495 9.26457 23.4335C9.09831 23.6828 8.67922 23.5705 8.65987 23.2715C8.53694 21.3714 8.12622 17.2835 6.80791 15.0002C5.4896 12.7168 2.15477 10.3172 0.570738 9.26067Z"
          fill="url(#paint0_linear_6195_10517)"
        />
        <g filter="url(#filter0_d_6195_10517)">
          <path
            d="M5.85128 1.9751C5.79257 1.68126 6.16832 1.46432 6.39344 1.66208C7.82391 2.9187 11.0049 5.51884 13.5517 6.20124C16.0984 6.88365 20.1533 6.22235 22.0204 5.84933C22.3143 5.79062 22.5312 6.16637 22.3335 6.39149C21.0768 7.82196 18.4767 11.0029 17.7943 13.5497C17.1119 16.0965 17.7732 20.1514 18.1462 22.0185C18.2049 22.3123 17.8292 22.5293 17.6041 22.3315C16.1736 21.0749 12.9926 18.4748 10.4458 17.7924C7.89906 17.1099 3.84419 17.7712 1.97705 18.1443C1.68321 18.203 1.46628 17.8272 1.66404 17.6021C2.92065 16.1716 5.52079 12.9907 6.2032 10.4439C6.8856 7.8971 6.2243 3.84223 5.85128 1.9751Z"
            fill="url(#paint1_linear_6195_10517)"
          />
        </g>
        <path
          d="M7.98689 11.8398C7.84552 11.8894 7.84552 12.1088 7.98689 12.1584C8.66669 12.3969 9.88717 12.8852 10.5011 13.4991C11.115 14.113 11.6033 15.3335 11.8418 16.0133C11.8914 16.1547 12.1108 16.1547 12.1604 16.0133C12.3989 15.3335 12.8872 14.113 13.5011 13.4991C14.115 12.8852 15.3355 12.3969 16.0153 12.1584C16.1566 12.1088 16.1566 11.8894 16.0153 11.8398C15.3355 11.6013 14.115 11.113 13.5011 10.4991C12.8872 9.88522 12.3989 8.66474 12.1604 7.98494C12.1108 7.84356 11.8914 7.84356 11.8418 7.98494C11.6033 8.66474 11.115 9.88522 10.5011 10.4991C9.88717 11.113 8.66669 11.6013 7.98689 11.8398Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6195_10517"
          x="0.0878906"
          y="0.0859375"
          width="23.8217"
          height="23.8223"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6195_10517" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6195_10517"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6195_10517"
          x1="3.00173"
          y1="17.999"
          x2="8.79597"
          y2="-1.81678"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D74C1B" />
          <stop offset="0.248346" stopColor="#EF6636" />
          <stop offset="0.552718" stopColor="#E7936B" />
          <stop offset="0.977469" stopColor="#FFE5D4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6195_10517"
          x1="6.00185"
          y1="1.4987"
          x2="22.9252"
          y2="17.196"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D74C1B" />
          <stop offset="0.248346" stopColor="#EF6636" />
          <stop offset="0.552718" stopColor="#E7936B" />
          <stop offset="0.977469" stopColor="#FFE5D4" />
        </linearGradient>
        <clipPath id="clip0_6195_10517">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 24 24',
}
