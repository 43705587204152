import React from 'react'
export const exchange = {
  path: (
    <g fill="currentColor">
      <path
        d="M18.4308 6.40002L22.271 11.2003H6.39984L4.79999 14.4L25.6 14.4003C26.215 14.4003 26.7756 14.0478 27.0421 13.4934C27.3085 12.9391 27.2336 12.2811 26.8494 11.8008L22.5288 6.40002H18.4308Z"
        key="YitmDD3og"
      />
      <path
        d="M9.47124 25.6003H13.5692L9.729 20.8H25.6002L27.2 17.6003L6.4 17.6C5.78495 17.6 5.22435 17.9526 4.95792 18.5069C4.69149 19.0613 4.7664 19.7193 5.15061 20.1995L9.47124 25.6003Z"
        key="uTHRCz0Iqz"
      />
    </g>
  ),
  viewBox: '0 0 32 32',
}
