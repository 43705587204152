import React from 'react'
export const arrow_left = {
  path: (
    <path
      d="M12.1587 22.6768L13.7773 21.0581L5.26514 12.5649H28.9165V10.2988H5.26514L13.7773 1.80566L12.1587 0.206055L0.961426 11.4414L12.1587 22.6768Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 29 23',
}
